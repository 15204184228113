
/* CustomInput.css */
.custom-outline {
    border: none;
    outline: none;
    box-shadow: none;
}

.custom-outline:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.custom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 50px;
}

.custom-label {
    position: absolute;
    top: -7px;
    left: 12px;
    background-color: white;
    padding: 5px;
    font-size: 10px;
}

.icon-start, .icon-end {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.icon-start {
    left: 12px;
}

.icon-end {
    right: 12px;
}

.custom-outline {
    padding-left: 36px !important; 
    padding-right: 36px !important; 
}
