// Brand colors
$darkBlueNormal: #0c022f;
$darkBlueLight: #b4b1bf; //More of a light gray
$darkBlueDark: #090223;
$lightBlue: #E7E6EA; // Very light blue:hover in design system

$grayLight: #ddd;
$grayBg: #373737;

$BlueNormal: #005ce4;
$blueLight: #b0ccf7;

$PinkNormal: #CB2C84;
$PinkLight: #f6bfde;

// Font sizings - use these variable in the font-size() mixin for converting to rem sizes
$base-font-size: 16px;
$h1: 30px;
$h2: 25px;
$h3: 22px;
$h4: 20px;