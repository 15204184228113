@import '../../Styles/global-imports.scss';
    
.vertical-tabs-container {
    display: inline-block;
    flex-direction: row;
}

.vertical-tabs-container {
    display: inline-grid;
}

div.staff-searchmodal {
    max-width: 1700px;
}

div.staffsearch-body {
   display: block;
}

input.staff-searchinput {
    width: 250px;
}

span.page-link, a.page-link {
    border-radius: 8px;
}
.buttoncenter {
    justify-content: center !important;
}