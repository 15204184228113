.modal-loading {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 150000;
    background: rgba(76, 157, 213, 0.6) 50% 50% no-repeat;
    overflow: hidden;
    transition: all ease 1.5s;
}

.modal-loading img {
    width: 20%;
    border-radius: 50%;
}
