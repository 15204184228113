@font-face {
    font-family: 'Inter-bold';
    src: url('../../fonts/Inter-Bold.woff2') format('woff2'), url('../../fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-light';
    src: url('../../fonts/Inter-Light.woff2') format('woff2'), url('../../fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-medium';
    src: url('../../fonts/Inter-Medium.woff2') format('woff2'), url('../../fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    src: url('../../fonts/Inter-Regular.woff2') format('woff2'), url('../../fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Inter-medium';
}

h1 {
    font-family: 'Inter-medium';
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

h2 {
    font-family: 'Inter-regular';
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

h3 {
    font-family: 'Inter-medium';
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
}

p {
    font-family: 'Inter-medium';
    margin: 0px;
    line-height: 1.5;
}