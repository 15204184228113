@import 'bootstrap/dist/css/bootstrap.min.css';
@import './Styles/global-imports';
/* Provide sufficient contrast against white background */
main {
    @include themed() {
        background: t($bg-body);
    }

    -webkit-text-stroke: .025em rgba(51,51,51,0.50);
}

a {
  color: #0366d6;
}
.dropdown-menu {
    box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.40);
}
.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}
