// Import variables and mixins
@import '../../Styles/global-imports.scss';

.filterbar-container {
    .dropdown-toggle {
        margin-top: 0 !important;
    }
    padding: 0em 2em;
    margin-top: 75px;
}

.filter-pill {
    &:hover {
        cursor: pointer;
    }

    .filter-key, .filter-count {
        font-size: 12px;
    }

    .form-check-inline {
        margin-right: 0.5rem;
    }

    .form-check-input[type="checkbox"] {
        border-radius: 1.25em;
        margin-top: 0.15em;
    }

    &:hover {
        cursor: pointer;
    }

    border-radius: 15px;
    padding: 0.1em 0.8em 0.1em 0.5em;
    line-height: 1.3;
    color: white;
    @include themed() {
        background: t($bg-secondary);
    }
}
.custom-tooltip {
    .tooltip-inner {
        background: linear-gradient(-68deg,#00AFFA,#0C022F );
        color: white; /* Text color */
    }

    .tooltip-arrow {
        &::before {
            background: linear-gradient(-68deg,#00AFFA,#0C022F );
        }
    }
}