@import './Variables';
@import './themes';
/* ----------------------------- DARK / LIGHTMODE THEME ------------------------  */

/*  
    Use @include themed() {} to add styles to be switchable when theme--light or dark is toggled
    Additional variables within _variables.scss file so use that file to add additional themes 
*/
@mixin themed() {
    @each $theme, $map in $themes {
        .theme--#{$theme} & {
            $theme-map: () !global;

            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}
/* 
    This function controls the variables and strings to be used within the mixin above 
*/
@function t($key) {
    @return map-get($theme-map, $key);
}

/* ----------------------------- POSITION ------------------------  */

/* 
    Center div horizontal or vertical

*/
@mixin absolutecenter($axis: "both") {
    position: absolute;

    @if $axis == "y" {
        top: 50%;
        transform: translateY(-50%);
    }

    @if $axis == "x" {
        left: 50%;
        transform: translateX(-50%);
    }

    @if $axis == "both" {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

/* ----------------------------- ANIMATION RELATED MIXINS ------------------------  */

@mixin transition($args: background-color 0.5s ease) { /// background-color 0.5s ease
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin transform($arg) { /// scale(1), rotate(-45deg
    -webkit-transform: $arg;
    -moz-transform: $arg;
    -o-transform: $arg;
    -ms-transform: $arg;
    transform: $arg;
}

@mixin animation($arg) { /// spin 0.8s linear infinite
    -webkit-animation: $arg;
    animation: $arg;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); //IE8
}

/* ----------------------------- TYPOGRAPHY MIXINS ------------------------  */

// Calculate pixel font sizes and convert to rem values
@mixin font-size($size) {
    @if unitless($size) {
        $size: $size * 1px;
    }

    font-size: $size;
    font-size: calc($size / $base-font-size) * 1rem;
}

/* ----------------------------- FUNCTIONS ------------------------  */

@function black($opacity) {
    @return rgba(0, 0, 0, $opacity);
}

@function white($opacity) {
    @return rgba(255, 255, 255, $opacity);
}

@function calc-percent($target, $container) {
    @return ($target / $container) * 100%;
}

