@import './variables';
@import './mixins';
@import './themes';

.btn-primary {
   background: $PinkNormal;
}

.btn {
    &.btn-primary {
        &:hover {
            cursor: pointer;
            @include themed() {
                background: t($buttonHoverPrimary);
            }
        }

        border-radius: 6px;
        padding: 10px 20px;
        border: none;
        color: white;
        @include transition;

        @include themed() {
            background: t($buttonPrimary);
        }
    }

    &.btn-secondary {
        &:hover {
            @include themed() {
                background: t($buttonHoverSecondary);
            }
        }

        border-radius: 6px;
        padding: 10px 20px;
        border: none;
        color: white;
        @include transition;

        @include themed() {
            background: t($buttonSecondary);
        }
    }

    &.btn-outline {
        &:hover {

            @include themed() {
                border: 1px solid t($buttonHoverOutline);
                color: t($buttonHoverOutline);
            }
        }

        background: none;
        border-radius: 6px;
        padding: 10px 20px;
        @include transition(all 0.5s ease);

        @include themed() {
            border: 1px solid t($buttonOutline);
            color: t($buttonOutline);
        }
    }

    @include transition;
}
