@import './global-imports.scss';

html, body {
    height: 100%;
    background-attachment: fixed;
    -webkit-font-smoothing: antialiased;
}

.header-container {
    display: flex;
    justify-content: flex-end;
    margin: 50px 55px 0 0;
    width: 98%;
    position: absolute;
    top: 0;
    @include transition;
}

#headerContainerLogo {
    height: 70px;
    /*width: 210px;*/
    object-fit: cover;
}

#headerContainerLogoSmallScreen {
    display: none;
}

.input-icon-start,
.input-icon-end {
    position: absolute;
    font-size: 18px;
    top: 15px;
}

.input-icon-start {
    left: 10px;
}

.input-icon-end {
    right: 10px;
}

.input-label {
    position: absolute;
    top: -12px;
    left: 40px;
    background-color: white;
    padding: 0 5px;
    font-size: 14px;
}

.right-side-title {
    color: white;
    position: relative;
    margin-right: 5%;
}

.title-instruction {
    margin-bottom: 20%;
    font-size: 24px;
    font-family: normsProRegular, Arial;
}

span.instruction {
    font-size: 30px;
    line-height: 1.2;
    font-family: normsProMedium, Arial;
}

.login-page {
    min-height: 100vh;
    background-image: url('../images/loginPage.jpg');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    background-color: white;
    position: relative;
    border-radius: 25px;
    padding: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 530px;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.logo {
    max-width: 200px;
    height: 60px;
}

.component-container__photo-background {
    background-color: #313366;
    height: 100%;
    width: 37%;
    position: absolute;
    right: 0;
    box-shadow: 0 0 75px 80px #313366;
}

.user-login-subtitle {
    width: 100%;
    text-align: center;
    color: #313366;
    font-family: cabinBold, Arial;
    font-size: 18px;
}

.user-login-subtitle p {
    margin: 0;
}

button.login-button.btn {
    background-color: #dd007d;
    height: 45px;
    width: 150px;
    border: none;
    border-radius: 25px;
    margin-top: 30px;
    font-family: cabinBold, Arial;
    cursor: pointer;
    font-size: 17px;
}

.input-group {
    position: relative;
}

.input-label {
    position: absolute;
    top: -12px;
    left: 15px;
    background-color: white;
    padding: 0 5px;
    font-size: 14px;
}

.input-icon {
    position: absolute;
    font-size: 18px;
    top: 15px;
}

    .input-icon:first-child {
        left: 10px;
    }

    .input-icon:last-child {
        right: 10px;
    }

.forgotten-password {
    font-size: 10px;
    font-family: normsProMedium, Arial;
    color: gray;
    cursor: pointer;
}

.custom-input {
    padding: 10px 40px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 14px;
}

.store-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

    .store-icons a {
        margin: 0 5px;
    }

    .store-icons img {
        width: 100%;
        max-width: 150px;
        height: 30px;
    }

.app-text {
    height: 40px;
    width: 100%;
    color: black;
    text-align: center;
    font-family: normsProRegular, Arial;
    font-size: 13px;
}

.app-text p {
    margin: 0;
}

footer {
    position: absolute;
    bottom: 0;
    width: 99%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-text {
    margin-left: 20px;
    color: white;
    max-width: 410px;
}

.footer-icons {
    display: flex;
    justify-content: center;
    margin-right: 20px;
}

.footer-icons img {
    width: 100%;
    max-width: 45px;
    margin: 0 5px;
}

img.sage {
    max-width: 100px;
}

@media only screen and (max-width: 760px) {

    .component-container__photo-background {
        width: 100%;
        height: 20%;
        top: 0;
    }

    #headerContainerLogoSmallScreen {
        display: block;
        height: 60px;
    }

    .login-page {
        padding: 5%;
        background-image: url('../images/login_background_2.jpg');
        background-size: cover;
        background-position: initial;
    }

    .logo {
        display: none;
    }

    .login-container{
        height: 430px;
    }

    .header-container {
        position: relative;
        margin-bottom: 55px;
        justify-content: space-between;
    }

    .right-side-title {
        flex-flow: row wrap;
        text-align: center;
        margin: 0;
    }

    .title-instruction{
        margin-bottom: 10%;
        font-size: 18px;
    }

    span.instruction {
        font-size: 28px;
    }

    footer {
        position: relative;
        flex-direction: column;
        margin-top: 2em;
        align-items: center;
    }

    .footer-text {
        text-align: center;
        font-size: 17px;
        font-family: normsProRegular, Arial, sans-serif;
        max-width: 310px;
        max-height: 70px;
    }

    .footer-icons {
        flex-wrap: wrap;
        margin-top: 2em;
        margin-right: 0;
        max-width: 234px;
    }

    .footer-icons img {
        max-width: 55px;
    }

    img.sage {
        max-width: 160px;
    }

}

