@import './global-imports.scss';

/* Default Body Styles */
body {
    font-family: Inter-regular!important;
    font-size: $base-font-size!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: always;
}

/*Enure all the chains to be 100% height by default*/
html, body, #root {
    height: 100%;
}

/********************************************************************************/
/* Default List Styles */
li a {
    color: white;
    transition: 0.1s;
}

/********************************************************************************/
/* Scrollbar */

::-webkit-scrollbar {
    &:hover {
        cursor: pointer;
    }

    width: 10px;
    height: 7px;
}

::-webkit-scrollbar-button {
    width: 2px;
    height: 0px;
}

/* Scroll bar slider */
::-webkit-scrollbar-thumb {
    &:hover {
        background: #de4767;
        cursor: pointer;
    }

    &:active {
        background: #30365d;
    }

    background: $BlueNormal;
    border: 0px solid #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

/* Scroll bar background */
::-webkit-scrollbar-track {
    background: #e9e9e9;
    border: 0px none #ffffff;
}

